export default function fDropdown() {
  const aDropdowns = document.querySelectorAll('.js-dropdown');

  const openDropdown = (toggle, menu) => {
    toggle.setAttribute('aria-expanded', 'true');
    menu.classList.add('is-open');
  };

  const closeDropdown = (toggle, menu) => {
    toggle.setAttribute('aria-expanded', 'false');
    menu.classList.remove('is-open');
  };

  const toggleDropdown = (toggle, menu) => {
    if (toggle.getAttribute('aria-expanded') === 'false') {
      openDropdown(toggle, menu);
    } else {
      closeDropdown(toggle, menu);
    }
  };

  const closeAll = (e) => {
    if (!e.target.closest('.js-dropdown')) {
      aDropdowns.forEach((dropdown) => {
        const dropdownToggle = dropdown.querySelector('.dropdown__toggle');
        const dropdownMenu = dropdown.querySelector('.dropdown__menu');

        closeDropdown(dropdownToggle, dropdownMenu);
      });
    }
  };

  document.addEventListener('click', (e) => closeAll(e));

  aDropdowns.forEach((dropdown) => {
    const dropdownToggle = dropdown.querySelector('.dropdown__toggle');
    const dropdownMenu = dropdown.querySelector('.dropdown__menu');

    dropdownToggle.addEventListener('click', () => {
      toggleDropdown(dropdownToggle, dropdownMenu);
    });
  });
}
