import isMobile from 'ismobilejs';

export default function fVideo() {
  const video = document.querySelector('.js-video');
  const videoControl = document.querySelector('.js-video-control');
  const videoImageFallback = document.querySelector('.js-video-image');

  const playIcon = videoControl.querySelector('.section-hero__control__play-icon');
  const pauseIcon = videoControl.querySelector('.section-hero__control__pause-icon');

  const showPauseIcon = () => {
    playIcon.setAttribute('style', 'display: none;');
    pauseIcon.setAttribute('style', 'display: block;');
  };

  const showPlayIcon = () => {
    playIcon.setAttribute('style', 'display: block;');
    pauseIcon.setAttribute('style', 'display: none;');
  };

  if (!isMobile.any) {
    videoImageFallback.style.display = 'none';
    video.play();
    video.classList.add('is-loaded');
    showPauseIcon();
  }

  const controlVideo = () => {
    if (video.playing) {
      video.pause();
      showPlayIcon();
    } else {
      video.play();
      showPauseIcon();

      if (!video.classList.contains('is-loaded')) {
        video.classList.add('is-loaded');
      }
    }
  };

  videoControl.addEventListener('click', controlVideo);
}
