import factory from './vendor/validate';

export default function fFormValidation() {
  const validate = factory();
  const { messages } = window;

  validate.init({
    // Classes and Selectors
    selector: '.js-form-validate',
    fieldClass: 'has-error',
    errorClass: 'form__group__error',

    // Messages
    messageValueMissing: messages.messageValueMissing || 'Please fill this input',
    messageValueMissingSelect: messages.messageValueMissingSelect || 'Please select a value',
    messageValueMissingSelectMulti: messages.messageValueMissingSelectMulti || 'Please select at least one value',
    messageTypeMismatchEmail: messages.messageTypeMismatchEmail || 'Please enter an email address',
    messageTypeMismatchURL: messages.messageTypeMismatchURL || 'Please enter a URL',
    messageTooShort: messages.messageTooShort || 'Please lengthen this text to {minLength} characters or more. You are currently using {length} characters',
    messageTooLong: messages.messageTooLong || 'Please shorten this text to no more than {maxLength} characters. You are currently using {length} characters',
    messagePatternMismatch: messages.messagePatternMismatch || 'Please match the requested format',
    messageBadInput: messages.messageBadInput || 'Please enter a number',
    messageStepMismatch: messages.messageStepMismatch || 'Please select a valid value',
    messageRangeOverflow: messages.messageRangeOverflow || 'Please select a value that is no more than {max}',
    messageRangeUnderflow: messages.messageRangeUnderflow || 'Please select a value that is no less than {min}',
    messageGeneric: messages.messageGeneric || 'The value you entered for this field is invalid',

    // Form Submission
    disableSubmit: true,
    onSubmit(form) {
      if (form.querySelector('.g-recaptcha')) {
        grecaptcha.reset();
        grecaptcha.execute();
      } else {
        form.submit();
      }
    },
  });
}
