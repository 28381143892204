import docCookies from 'doc-cookies';

export default function fRedirect() {
  const redirectForm = document.querySelector('.js-redirect');
  const redirectSelect = redirectForm.elements.RedirectSelect;
  const submitButton = redirectForm.querySelector('[data-redirect-submit]');
  const cancelButton = redirectForm.querySelector('[data-redirect-cancel]');

  const redirectSubmit = (e) => {
    e.preventDefault();
    docCookies.setItem('selectedLanguage', redirectSelect.selectedOptions[0].value, Infinity, '/');
    if (redirectSelect.selectedOptions[0].hasAttribute('data-url')) {
      window.location.replace(redirectSelect.selectedOptions[0].getAttribute('data-url'));
    } else {
      // Stay on current page
      window.location.replace(window.location.href);
    }
  };

  const redirectCancel = () => {
    docCookies.setItem('selectedLanguage', document.documentElement.lang, Infinity, '/');
    window.location.replace(window.location.origin);
  };

  submitButton.addEventListener('click', redirectSubmit);
  cancelButton.addEventListener('click', redirectCancel);
}
