export default function initSearch() {
  const searchForms = document.querySelectorAll('.js-search');

  const initSearchForm = (form) => {
    const searchForm = form;
    const searchInput = searchForm.querySelector('.js-search-input');
    const searchWrapper = searchForm.querySelector('.search__wrapper');
    const sApiUrl = searchForm.getAttribute('data-api-url');
    const iLanguageId = parseInt(searchForm.getAttribute('data-language-id'), 10);

    const autoCompleteWrapper = document.createElement('div');
    autoCompleteWrapper.setAttribute('class', 'search__autosuggest');

    const getAutosuggestHtml = (data, searchValue) => `<span class="search__autosuggest__label capitalize">${data.LabelProducts}</span>
                ${data.Products.length ? `<ul>
                    ${data.Products.map((product) => `<li><a href="${product.Url}">${product.Title}</a></li>`).join('')}
                    <li><a href="${data.ProductOverviewUrl}" class="all">${data.LabelShowProductResults}</a></li>
                </ul>` : `<p>${data.LabelNoResults}</p>`}
                <span class="search__autosuggest__label capitalize">${data.LabelFrequentlyAskedQuestions}</span>
                ${data.FaqItems.length ? `<ul>
                    ${data.FaqItems.map((faq) => `<li><a href="${faq.Url}">${faq.Title}</a></li>`).join('')}
                </ul>` : `<p>${data.LabelNoResults}</p>`}
                <a href="${data.SearchResultsUrl}" class="button button--secondary button--small">${data.LabelShowSearchResults} '${searchValue}'</a>`;

    const hideAutosuggest = (e) => {
      const as = document.querySelector('.search__autosuggest');

      if (e.type === 'blur') {
        if (as) {
          as.remove();
        }
        return;
      }

      if (e.target !== searchInput) {
        if (as) {
          as.remove();
        }
      } else if (e.target.value.length < 3) {
        if (as) {
          as.remove();
        }
      }
    };

    const search = (e) => {
      if (e.target.value.length >= 3) {
        fetch(`${sApiUrl}?q=${e.target.value}&language=${iLanguageId}`, {
          method: 'GET',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            autoCompleteWrapper.innerHTML = getAutosuggestHtml(data, e.target.value);
            searchWrapper.appendChild(autoCompleteWrapper);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        hideAutosuggest(e);
      }
    };

    document.addEventListener('click', hideAutosuggest);

    if (searchInput) {
      searchInput.addEventListener('input', search);
      searchInput.addEventListener('focus', search);
    }
  };

  searchForms.forEach((form) => {
    initSearchForm(form);
  });
}
