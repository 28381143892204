import Stickyfill from 'stickyfilljs';
import scrollIt from './helpers';

export default function fPageNavigation() {
  const pageNavigation = document.querySelector('.js-page-navigation');
  const pageNavItems = document.querySelectorAll('.js-page-navigation a');
  const pageSections = document.querySelectorAll('.section');
  let iOffsetHeight = pageNavigation.offsetHeight;
  let iMenuTopOffset = pageNavigation.offsetTop;

  Stickyfill.addOne(pageNavigation);

  window.onresize = () => {
    iOffsetHeight = pageNavigation.offsetHeight;
    iMenuTopOffset = pageNavigation.offsetTop;
  };

  window.onscroll = () => {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

    // if the page navigation is at the top of the viewport
    // mark the navigation element as sticky with a class
    // otherwise unmark it
    if (pageNavigation.getBoundingClientRect().top <= 0
        || (iMenuTopOffset >= 0 && iMenuTopOffset <= scrollPosition)) {
      pageNavigation.classList.add('page-navigation--sticky');
    } else if (pageNavigation.classList.contains('page-navigation--sticky')) {
      pageNavigation.classList.remove('page-navigation--sticky');
    }

    // check which section is at or under the page navigation
    // mark that section as the active section with a class
    // unmark all the other sections
    for (let i = 0; i < pageSections.length; i += 1) {
      if (pageSections[i].offsetTop - iOffsetHeight <= scrollPosition) {
        for (let p = 0; p < pageNavItems.length; p += 1) {
          const item = pageNavItems[p];

          if (item.classList.contains('active')) {
            item.classList.remove('active');
          }

          if (item.getAttribute('href') === `#${pageSections[i].id}`) {
            item.classList.add('active');
          }
        }
      }
    }
  };

  const scrollToSection = (event) => {
    const scrollDest = document.getElementById(event.target.getAttribute('href').slice(1));

    scrollIt(scrollDest, undefined, 400, 'easeInQuad', iOffsetHeight);
    event.preventDefault();
  };

  for (let p = 0; p < pageNavItems.length; p += 1) {
    pageNavItems[p].addEventListener('click', (e) => {
      scrollToSection(e);
    });
  }
}
