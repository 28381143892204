export default function fAlert() {
  const $alert = document.querySelector('.js-alert');
  const $closeButton = $alert.querySelector('[data-alert-close]');

  const closeAlert = () => {
    $alert.remove();
    if (document.body.classList.contains('alert-open')) {
      document.body.classList.remove('alert-open');
    }
  };

  $closeButton.addEventListener('click', closeAlert.bind(this));
}
