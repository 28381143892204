import gsap from 'gsap';

class Modal {
  constructor(id, content, videoId) {
    this.modal = document.querySelector(`.modal[data-id="${id}"]`);
    this.modalBody = this.modal.querySelector('.modal__body');
    this.content = content;
    this.videoId = videoId;
    this.closers = this.modal.querySelectorAll('.js-modal-close');

    // Create modal background
    if (document.querySelector('.modal-bg')) {
      this.modalBg = document.querySelector('.modal-bg');
    } else {
      this.modalBg = document.body.appendChild(document.createElement('div'));
      this.modalBg.classList.add('modal-bg');
    }

    // If modal has a form, reset it first
    const form = this.modal.querySelector('.form');
    if (form) {
      form.reset();
    }

    this.populate();
    this.watch();
  }

  watch() {
    if (this.modalBg) {
      this.modalBg.addEventListener('click', (event) => {
        this.close(event);
      });
    }

    if (this.closers) {
      for (let i = 0; i < this.closers.length; i += 1) {
        this.closers[i].addEventListener('click', (event) => {
          this.close(event);
        });
      }
    }
  }

  populate() {
    // Loop over each key/value pair in json data
    if (this.content) {
      this.data = JSON.parse(this.content);

      Object.entries(this.data).forEach(([key, value]) => {
        // Get corresponding field in modal
        const fields = this.modal.querySelectorAll(`.js-modal-${key}`);

        // Fill field with data value
        for (let a = 0; a < fields.length; a += 1) {
          if (typeof value === 'object') {
            for (let b = 0; b < value.length; b += 1) {
              const template = fields[a].parentNode.appendChild(fields[a].cloneNode(true));

              Object.entries(value[b]).forEach(([subkey, subvalue]) => {
                const subfield = template.querySelector(`.js-modal-${subkey}`);

                if (subfield.tagName === 'INPUT') {
                  subfield.value = subvalue;
                } else {
                  subfield.innerHTML = subvalue;
                }
              });
            }

            fields[a].style.display = 'none';
          } else if (fields[a].tagName === 'INPUT') {
            fields[a].value = value;
          } else {
            fields[a].innerHTML = value;
          }
        }
      });
    }

    if (this.videoId) {
      const videoEmbed = this.modal.querySelector('.js-video-embed');
      videoEmbed.setAttribute('src', `//youtube.com/embed/${this.videoId}?autoplay=1&rel=0&enablejsapi=1`);
    }

    this.showContent();
  }

  open() {
    const tl = gsap.timeline();

    // Defaults
    tl.timeScale(3);
    // TweenLite.defaultEase = Quart.easeOut;

    document.body.classList.add('no-scroll');

    // Animate
    if (this.modal.classList.toString().indexOf('modal--bottom') > -1) {
      tl.set(this.modal, { display: 'block' })
        .set(this.modalBg, { display: 'block' })
        .to(this.modal, 1, { autoAlpha: 1, y: '-50%' })
        .to(this.modalBg, 0.5, { ease: 'linear', autoAlpha: 1 }, 0);
    } else {
      tl.set(this.modal, { display: 'block' })
        .set(this.modalBg, { display: 'block' })
        .to(this.modal, 1, { autoAlpha: 1, x: '0%' })
        .to(this.modalBg, 1, { autoAlpha: 1 }, '-=.8');
    }
  }

  close(event) {
    event.preventDefault();

    const tl = gsap.timeline();

    const destroyModalBg = () => {
      this.modalBg.remove();
    };

    document.body.classList.remove('no-scroll');

    tl.timeScale(3);
    // TweenLite.defaultEase = Quart.easeOut;

    if (this.modal.classList.toString().indexOf('modal--bottom') > -1) {
      tl.to(this.modalBody, 1, { autoAlpha: 0 })
        .to(this.modalBg, 1, { autoAlpha: 0 }, '-=1')
        .to(this.modal, 1, { autoAlpha: 0, y: '-40%' }, '-=1')
        .set(this.modalBg, { display: 'none' })
        .set(this.modal, { display: 'none', onComplete: destroyModalBg });
    } else {
      tl.to(this.modalBody, 1, { autoAlpha: 0 })
        .to(this.modalBg, 1, { autoAlpha: 0 }, '-=1')
        .to(this.modal, 1, { x: '100%' }, '-=1')
        .set(this.modalBg, { display: 'none' })
        .set(this.modal, { display: 'none', onComplete: destroyModalBg });
    }

    if (this.videoId) {
      const videoEmbed = this.modal.querySelector('.js-video-embed');
      videoEmbed.setAttribute('src', '');
    }
  }

  showContent() {
    const tl = gsap.timeline();

    tl.to(this.modalBody, 0.3, { autoAlpha: 1 });
  }
}

export default function initModal() {
  const aTriggers = document.querySelectorAll('.js-modal');

  for (let i = 0; i < aTriggers.length; i += 1) {
    aTriggers[i].addEventListener('click', (event) => {
      event.preventDefault();

      const modalId = aTriggers[i].getAttribute('data-modal-id');
      const modalContent = aTriggers[i].getAttribute('data-modal-content');
      const videoId = aTriggers[i].getAttribute('data-video');

      if (!modalId) return;

      const modal = new Modal(modalId, modalContent, videoId);
      modal.open();
    });
  }
}
