import Flickity from 'flickity';

export default function initMobileSliders(mobileBreakpoint) {
  const sliders = document.querySelectorAll('.js-mobile-slider');
  const aFlickitySliders = [];
  const mediaQuery = window.matchMedia(`(max-width: ${mobileBreakpoint}px)`);

  function initFlickity(slider) {
    return new Flickity(slider, {
      cellAlign: 'left',
      prevNextButtons: false,
    });
  }

  for (let i = 0; i < sliders.length; i += 1) {
    const slider = sliders[i];
    if (mediaQuery.matches) {
      const oSlider = initFlickity(slider);
      aFlickitySliders.push(oSlider);
    }
  }

  window.onload = () => {
    for (let i = 0; i < aFlickitySliders.length; i += 1) {
      aFlickitySliders[i].resize();
    }
  };

  window.addEventListener('resize', () => {
    for (let i = 0; i < sliders.length; i += 1) {
      const oSlider = sliders[i];
      const oFlickSlider = aFlickitySliders[i];
      if (mediaQuery.matches) {
        if (!oFlickSlider) {
          const createdFlickSlider = initFlickity(oSlider);
          aFlickitySliders.push(createdFlickSlider);
        }
      } else if (oFlickSlider) {
        oFlickSlider.destroy();
        aFlickitySliders.splice(0, 1);
      }
    }
  });
}
