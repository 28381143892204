import docCookies from 'doc-cookies';

export default function initNavigation() {
  const oMenuToggles = document.querySelectorAll('.js-toggle-menu');
  const oMobileMenu = document.querySelector('.js-mobile-nav');

  const oLanguageToggles = document.querySelectorAll('.js-toggle-language');
  const oLanguageNav = document.querySelector('.js-language-nav');
  const oLanguageNavItems = oLanguageNav ? oLanguageNav.querySelectorAll('.language-navigation__link') : null;

  const oSiteWrapper = document.querySelector('.site-wrapper');
  const oMainMenuItems = document.querySelectorAll('#main-navigation .navigation__item');

  const searchToggle = document.querySelector('.js-toggle-search');
  const searchForm = document.querySelector('.js-search');
  let searchOpen = false;

  const openMainNavSubmenu = (index) => {
    const oMenuItem = event.target;
    const aMainMainItems = Array.prototype.slice.call(oMainMenuItems);
    aMainMainItems.splice(index, 1);

    if (oMenuItem.classList.contains('js-submenu')) {
      document.body.classList.add('faded');
      for (let i = 0; i < aMainMainItems.length; i += 1) {
        aMainMainItems[i].classList.add('navigation__item--hidden');
      }
    }
  };

  const closeMainNavSubmenu = (index) => {
    const oMenuItem = event.target;
    const aMainMainItems = Array.prototype.slice.call(oMainMenuItems);
    aMainMainItems.splice(index, 1);

    document.body.classList.remove('faded');

    if (oMenuItem.classList.contains('js-submenu')) {
      for (let i = 0; i < aMainMainItems.length; i += 1) {
        aMainMainItems[i].classList.remove('navigation__item--hidden');
      }
    }
  };

  const toggleMobileNav = () => {
    if (oMobileMenu.classList.contains('active')) {
      oMobileMenu.classList.remove('active');
      document.body.classList.remove('no-scroll');
    } else {
      oMobileMenu.classList.add('active');
      if (!document.body.classList.contains('no-scroll')) {
        document.body.classList.add('no-scroll');
      }
    }
  };

  const toggleLanguageNav = () => {
    if (oLanguageNav.classList.contains('active')) {
      oLanguageNav.classList.remove('active');
      document.body.classList.remove('no-scroll');
    } else {
      oLanguageNav.classList.add('active');
      if (!document.body.classList.contains('no-scroll')) {
        document.body.classList.add('no-scroll');
      }
    }
  };

  const addMainMenuItemsListeners = (i) => {
    oMainMenuItems[i].addEventListener('mouseenter', () => {
      openMainNavSubmenu(i);
    });

    oMainMenuItems[i].addEventListener('mouseleave', () => {
      closeMainNavSubmenu(i);
    });
  };

  const addLanguageNavItemsListeners = (i) => {
    oLanguageNavItems[i].addEventListener('click', (e) => {
      e.preventDefault();
      docCookies.setItem('selectedLanguage', e.currentTarget.getAttribute('data-code'), Infinity, '/');
      window.location.replace(e.currentTarget.href);
    });
  };

  const toggleSearch = () => {
    searchOpen = !searchOpen;
    oSiteWrapper.classList.toggle('search-open');

    if (searchOpen) {
      searchForm.elements.q.focus();
    }
  };

  for (let i = 0; i < oMenuToggles.length; i += 1) {
    oMenuToggles[i].addEventListener('click', toggleMobileNav);
  }

  for (let i = 0; i < oLanguageToggles.length; i += 1) {
    oLanguageToggles[i].addEventListener('click', toggleLanguageNav);
  }

  for (let i = 0; i < oLanguageNavItems?.length; i += 1) {
    addLanguageNavItemsListeners(i);
  }

  for (let i = 0; i < oMainMenuItems.length; i += 1) {
    addMainMenuItemsListeners(i);
  }

  if (searchToggle) {
    searchToggle.addEventListener('click', () => {
      toggleSearch();
    });
  }
}
