import Flickity from 'flickity';
import imagesLoaded from 'imagesloaded';

export default function fSliders() {
  const aSliders = document.querySelectorAll('.js-slider');

  const createSlider = (oSlider) => {
    imagesLoaded(oSlider, () => {
      const oFlickitySlider = new Flickity(oSlider, {
        cellAlign: 'left',
        wrapAround: oSlider.getAttribute('data-slider-wrap') === 'true',
        prevNextButtons: oSlider.getAttribute('data-slider-nav') === 'true',
        fullscreen: oSlider.getAttribute('data-slider-zoom') === 'true',
        lazyLoad: oSlider.getAttribute('data-slider-lazy') ? parseInt(oSlider.getAttribute('data-slider-lazy'), 10) : false,
      });
    });
  };

  for (let i = 0; i < aSliders.length; i += 1) {
    createSlider(aSliders[i]);
  }
}
