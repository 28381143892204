export default function fTabs() {
  const aTabs = document.querySelectorAll('.js-tabs');

  const initTabs = (tabs) => {
    const tabNav = tabs.querySelectorAll('[role="tab"]');
    const tabPanels = Array.from(tabs.querySelectorAll('[role="tabpanel"]'));

    const deactivateTabs = () => {
      tabNav.forEach((t) => {
        t.setAttribute('aria-selected', 'false');
      });

      tabPanels.forEach((p) => {
        p.setAttribute('hidden', 'hidden');
      });
    };

    const setActiveTab = (e) => {
      const tab = e.target;
      const tabPanelId = tab.getAttribute('aria-controls');
      const panel = tabPanels.filter((t) => t.id === tabPanelId)[0];

      deactivateTabs();
      tab.setAttribute('aria-selected', true);

      if (panel) {
        panel.removeAttribute('hidden');
      }
    };

    tabNav.forEach((tab) => {
      tab.addEventListener('click', setActiveTab);
    });
  };

  aTabs.forEach((tabs) => {
    initTabs(tabs);
  });
}
