export default function fStickyHeader(mobileBreakpoint) {
  const navBar = document.querySelector('.js-sticky-header');
  const stickyClass = 'header--sticky';
  let navBarHeight = navBar.offsetHeight;
  let isSticky = false;
  let watchScroll = false;
  const mediaQuery = window.matchMedia(`(max-width: ${mobileBreakpoint}px)`);

  const toggleStickyMenu = () => {
    if (navBar.classList.contains(stickyClass)) {
      navBar.classList.remove(stickyClass);
      document.body.style.paddingTop = null;
      isSticky = false;
    } else {
      navBar.classList.add(stickyClass);
      isSticky = true;

      if (!navBar.classList.contains('header--absolute')) {
        document.body.style.paddingTop = `${navBarHeight}px`;
      }
    }
  };

  const checkScrollPosition = () => {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollPosition > navBarHeight) {
      if (!isSticky) {
        toggleStickyMenu();
      }
    } else if (isSticky) {
      toggleStickyMenu();
    }
  };

  const addScrollListener = () => {
    window.addEventListener('scroll', checkScrollPosition);
    watchScroll = true;
  };

  const removeScrollListener = () => {
    window.removeEventListener('scroll', checkScrollPosition);
    watchScroll = false;

    if (isSticky) {
      toggleStickyMenu();
    }
  };

  window.addEventListener('resize', () => {
    navBarHeight = navBar.offsetHeight;

    if (mediaQuery.matches) {
      if (!watchScroll) {
        addScrollListener();
      }
    } else {
      removeScrollListener();
    }
  });

  if (mediaQuery.matches && !watchScroll) {
    addScrollListener();
  }
}
