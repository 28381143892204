import fAccordion from './accordion';
import fAlert from './alert';
import fApplication from './application';
import fDropdown from './dropdown';
import fFormValidation from './form-validation';
import fLazyLoad from './lazyload';
import initNavigation from './navigation';
import initMobileSliders from './mobile-sliders';
import initModal from './modal';
import fPageNavigation from './page-navigation';
import fStickyHeader from './sticky-header';
import fVideo from './video';
import fSliders from './sliders';
import fRedirect from './redirect';
import fTabs from './tabs';
import initSearch from './search';

const mobileBreakpoint = 992;

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('.js-lazy').length) { fLazyLoad(); }
  if (document.querySelectorAll('.js-accordion').length) { fAccordion(); }
  initNavigation();
  if (document.querySelectorAll('.js-mobile-slider').length) { initMobileSliders(mobileBreakpoint); }
  if (document.querySelectorAll('.js-form-validate').length) { fFormValidation(); }
  if (document.querySelectorAll('.js-application').length) { fApplication(); }
  if (document.querySelectorAll('.js-modal').length) { initModal(); }
  if (document.querySelectorAll('.js-page-navigation').length) { fPageNavigation(); }
  if (document.querySelectorAll('.js-sticky-header').length) { fStickyHeader(mobileBreakpoint); }
  if (document.querySelectorAll('.js-video').length) { fVideo(); }
  if (document.querySelectorAll('.js-slider').length) { fSliders(); }
  if (document.querySelectorAll('.js-redirect').length) { fRedirect(); }
  if (document.querySelectorAll('.js-alert').length) { fAlert(); }
  if (document.querySelectorAll('.js-dropdown').length) { fDropdown(); }
  if (document.querySelectorAll('.js-tabs').length) { fTabs(); }
  if (document.querySelectorAll('.js-search').length) { initSearch(); }
});

window.addEventListener('load', () => {
  // Restore scroll position after accepting cookies, has to be in a timeout to work in Chrome
  setTimeout(() => {
    const scrollpos = localStorage.getItem('scrollpos');
    if (scrollpos) {
      window.scrollTo(0, parseInt(scrollpos, 10));
    }
    localStorage.removeItem('scrollpos');
  }, 0);
});
