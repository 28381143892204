export default function fApplication() {
  const aApplicationBlocks = document.querySelectorAll('.js-application');

  const initApplications = (oApplicationBlock) => {
    const aApplications = oApplicationBlock.querySelectorAll('.application');
    const oApplicationImage = oApplicationBlock.querySelector('.application__image img');

    const changeApplicationImage = (event) => {
      const oApplication = event.currentTarget;
      const sImage = oApplication.getAttribute('data-image');
      const sImageX2 = oApplication.getAttribute('data-image-x2');

      oApplicationImage.setAttribute('src', sImage);
      oApplicationImage.setAttribute('srcset', sImageX2);
    };

    for (let a = 0; a < aApplications.length; a += 1) {
      aApplications[a].addEventListener('mouseenter', changeApplicationImage);
    }
  };

  for (let i = 0; i < aApplicationBlocks.length; i += 1) {
    initApplications(aApplicationBlocks[i]);
  }
}
